import * as Yup from 'yup';
import { ResetTabs } from '../../modules/Auth/ResetPassword';
import { OnboardingEntryTypes } from '../../enums/onboarding/crm';
import {
  CRM_CONTACT_NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
} from '../../constants/global';
import {
  EMAIL_VALIDATION_RULE,
  NULLABLE_STRING_VALIDATION_RULE,
  OTP_VALIDATION_RULE,
  PASSWORD_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
  PHONE_ASYNC_VALIDATION_RULE,
} from '../common';

export const EmailValidationSchema = Yup.object().shape({
  email: EMAIL_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'invitation:invitation_request.form_fields.email' },
  }),
});

export const UsernameValidationSchema = Yup.object().shape({
  // TODO: add special validation schema for username
  username: STRING_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:signin.steps.check_username.form_fields.username' },
  }),
});

export const PasswordValidationSchema = Yup.object().shape({
  password: PASSWORD_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:signin.steps.signin.form_fields.password' },
  }),
});

export const ActivateUserValidationSchema = Yup.object().shape({
  otpCode: OTP_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'auth:signin.steps.activate_account.form_fields.otp_code',
    },
  }),
  newPassword: PASSWORD_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'auth:signin.steps.activate_account.form_fields.new_password',
    },
  }),
  confirmNewPassword: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('newPassword'), ''],
    {
      key: 'form:validation_messages.invalid_value_with_label',
      values: {
        label:
          'auth:signin.steps.activate_account.form_fields.confirm_new_password',
      },
    },
  ).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label:
        'auth:signin.steps.activate_account.form_fields.confirm_new_password',
    },
  }),
});

export const SignUpValidationSchema = Yup.object().shape({
  firstName: CRM_NAME_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters_with_label',
    values: {
      label: 'auth:open_an_account.form_fields.first_name',
      number: NAME_MIN_LENGTH,
    },
  })
    .max(CRM_CONTACT_NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'auth:open_an_account.form_fields.first_name',
        number: CRM_CONTACT_NAME_MAX_LENGTH,
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'auth:open_an_account.form_fields.first_name' },
    }),

  lastName: CRM_NAME_VALIDATION_RULE.min(NAME_MIN_LENGTH, {
    key: 'form:validation_messages.min_characters_with_label',
    values: {
      label: 'auth:open_an_account.form_fields.last_name',
      number: NAME_MIN_LENGTH,
    },
  })
    .max(CRM_CONTACT_NAME_MAX_LENGTH, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'auth:open_an_account.form_fields.last_name',
        number: CRM_CONTACT_NAME_MAX_LENGTH,
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: { label: 'auth:open_an_account.form_fields.last_name' },
    }),

  email: EMAIL_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:open_an_account.form_fields.email' },
  }),

  phoneNumber: PHONE_ASYNC_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:open_an_account.form_fields.phone' },
  }),

  password: PASSWORD_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:open_an_account.form_fields.password' },
  }),

  passwordConfirm: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('password'), ''],
    'form:warnings.passwords_should_match',
  ).required('form:validation_messages.required'),

  accountType: NULLABLE_STRING_VALIDATION_RULE.oneOf([
    OnboardingEntryTypes.Contact,
    OnboardingEntryTypes.Organization,
  ]).required({
    key: 'form:validation_messages.required',
  }),

  keyRelationshipTemplateId: NULLABLE_STRING_VALIDATION_RULE.defined().when(
    'accountType',
    {
      is: OnboardingEntryTypes.Organization,
      then: (schema) => schema.required(),
    },
  ),
});

export const PhoneConfirmationValidationSchema = Yup.object().shape({
  code: OTP_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:one_time_password' },
  }),
});

export const UpdatePhoneValidationSchema = Yup.object().shape({
  phone: PHONE_ASYNC_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:open_an_account.form_fields.phone' },
  }),
});

export const TwoStepVerificationValidationSchema = Yup.object().shape({
  code: OTP_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'common:one_time_password' },
  }),
});

export const AccountRecoveryRequestSchema = Yup.object().shape({
  currentStep: Yup.number(),
  // Step 1 - Information tab
  email: EMAIL_VALIDATION_RULE.when('currentStep', {
    is: 1,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label: 'auth:account_recovery.information_step.form_fields.email',
        },
      }),
  }),
  phone: PHONE_ASYNC_VALIDATION_RULE.when('currentStep', {
    is: 1,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label: 'auth:account_recovery.information_step.form_fields.phone',
        },
      }),
  }),
});

export const ActivateAccountValidationSchema = Yup.object().shape({
  password: PASSWORD_VALIDATION_RULE.required(
    'form:validation_messages.required',
  ),
  passwordConfirm: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('password'), ''],
    'form:warnings.passwords_should_match',
  ).required('form:validation_messages.required'),
});

// Reset password schemas
export const ResetPasswordValidationSchema = Yup.object().shape({
  // TODO: username validation
  username: STRING_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.RequestResetPasswordUsername,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label:
            'auth:reset_password.steps.request_reset_password_username.form_fields.username',
        },
      }),
  }),

  verificationCode: OTP_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.CodeVerification,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'common:one_time_password' },
      }),
  }),

  verificationSmsCode: OTP_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.RequestResetPasswordSMS,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: { label: 'common:one_time_password' },
      }),
  }),

  selfieSrc: STRING_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.SelfieVerification,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.invalid_value_with_label',
        values: {
          label:
            'auth:account_recovery.id_verification_step.documents.selfie.title',
        },
      }),
  }),

  newPassword: PASSWORD_VALIDATION_RULE.when('currentStep', {
    is: ResetTabs.ResetPassword,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label: 'auth:reset_password.steps.new_password.form_fields.password',
        },
      }),
  }),

  confirmNewPassword: STRING_VALIDATION_RULE.oneOf(
    [Yup.ref('newPassword'), ''],
    'form:warnings.passwords_should_match',
  ).when('currentStep', {
    is: ResetTabs.ResetPassword,
    then: (schema) =>
      schema.required({
        key: 'form:validation_messages.required_with_label',
        values: {
          label:
            'auth:reset_password.steps.new_password.form_fields.confirm_password',
        },
      }),
  }),
});

export const VerifyEmailValidationSchema = Yup.object().shape({
  otp: OTP_VALIDATION_RULE.required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'auth:email_verification.form_fields.otp' },
  }),
});

export const ResetUsernameValidationSchema = Yup.object().shape({
  step: STRING_VALIDATION_RULE.required(),
  user: Yup.object().when('step', {
    is: 'user_data',
    then: (schema) =>
      schema.shape({
        name: STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'auth:reset_username.form_fields.name' },
        }),
        email: EMAIL_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'auth:reset_username.form_fields.email' },
        }),
        clientGroup: STRING_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'auth:reset_username.form_fields.client_group' },
        }),
        dateOfBirth: Yup.date()
          .nullable()
          .required({
            key: 'form:validation_messages.required_with_label',
            values: { label: 'auth:reset_username.form_fields.date_of_birth' },
          }),
      }),
  }),
  emailConfirmation: Yup.object().when('step', {
    is: 'email_confirmation',
    then: (schema) =>
      schema.shape({
        otp: OTP_VALIDATION_RULE.required({
          key: 'form:validation_messages.required_with_label',
          values: { label: 'auth:reset_username.form_fields.otp' },
        }),
      }),
  }),
});
